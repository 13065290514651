<template>
  <div class="main">
    <a-layout class="mlayout">
      <a-layout>
        <a-layout-content class="indexLayoutContent" style="padding: 0px">
          <div class="page flex-col">
            <!-- <div class="group_1 flex-col" style="height: 10rem">
              <div
                class="group_3 flex-row"
                v-if="barclass == 'closethis'"
                style="background-color: #000"
              >
                <div class="toplan">
                  <div class="image-wrapper_1 flex-col justify-between w-logo">
                    <a href="/">
                    <img
                      class="w-logo-img"
                      referrerpolicy="no-referrer"
                      src="../assets/imgs/logo.png"
                    />
                    </a>
                  </div>

                  <div class="searchdiv">
                    <input type="text" v-model="searchkey" />
                    <div class="search" @click="searchplist"></div>
                  </div>
                </div>

                <div class="darkback" :style="{ opacity: topbarOpacity }"></div>
              </div>
            </div> -->

            <div :class="`bardiv ${barclass}`">
              <div class="barlist">
                <template v-for="(item, index) in blinks">
                  <a class="bla" :key="index">
                    <div
                      class="blitem"
                      :key="index"
                      @click="
                        () => {
                          goDiv(item.class, item.url);
                        }
                      "
                    >
                      <span>{{ item.title }}</span>

                      <div class="lined">
                        <img
                          class="line"
                          referrerpolicy="no-referrer"
                          src="../assets/imgs/line.png"
                        />
                      </div>
                    </div>
                  </a>
                </template>

                  <div
                    class="blitem justify-between"
                    style="flex-direction: row"
                  >
                    <span
                      v-if="lag == 'en'"
                      @click="
                        () => {
                          changelag('');
                        }
                      "
                      >中</span
                    >
                    <span v-else style="color: rgba(231, 55, 74, 1)">中</span>
                    &nbsp;&nbsp;/&nbsp;&nbsp;
                    <span v-if="lag == 'en'" style="color: rgba(231, 55, 74, 1)"
                      >EN</span
                    >
                    <span
                      v-else
                      @click="
                        () => {
                          changelag('en');
                        }
                      "
                      >EN</span
                    >
                  </div>
              </div>
              <div class="toplan">
                <div class="image-wrapper_1 flex-col justify-between w-logo">
                  <img
                    v-if="from == 'index'"
                    class="w-logo-img"
                    referrerpolicy="no-referrer"
                    src="../assets/imgs/logo.png"
                  />
                  <img
                    v-if="from == 'ai'"
                    class="w-logo-img"
                    referrerpolicy="no-referrer"
                    src="../assets/imgs/logo-2.png"
                  />
                </div>

                <div class="closebar" @click="closeBardiv">
                  <img src="../assets/imgs/icon-close2.png" />
                </div>
              </div>

              <div class="bltouch" @click="closeBardiv"></div>
            </div>

            <div
              class="group_3 flex-row"
              v-if="barclass == 'closethis'"
              style="background-color: #000"
            >
              <div class="toplan">
                <div
                  class="image-wrapper_1 flex-col justify-between w-logo wllogo"
                >
                  <!-- <img v-if="from=='index'"
                    class="w-logo-img"
                    referrerpolicy="no-referrer"
                    src="../assets/imgs/logo.png"
                  />
                  <img v-if="from=='ai'"
                    class="w-logo-img"
                    referrerpolicy="no-referrer"
                    src="../assets/imgs/logo-2.png"
                  /> -->

                  <router-link
                    v-if="from == 'index'"
                    :to="lag == 'en' ? `/index/en` : `/index`"
                  >
                    <img
                      class="w-logo-img"
                      referrerpolicy="no-referrer"
                      src="../assets/imgs/logo.png"
                    />
                  </router-link>

                  <router-link
                    v-if="from == 'ai'"
                    :to="lag == 'en' ? `/ai/en` : `/ai`"
                  >
                    <img
                      class="w-logo-img"
                      referrerpolicy="no-referrer"
                      src="../assets/imgs/logo-2.png"
                    />
                  </router-link>
                </div>

                <div class="blinks justify-end">
                  <template v-for="(item, index) in blinks">
                    <span
                      @mouseover="
                        () => {
                          blinkshandleHover(index);
                        }
                      "
                      @touchstart="
                        () => {
                          blinkshandleHover(index);
                        }
                      "
                      v-if="bselectindex != index"
                      class="item flex-col justify-between"
                      :style="index == 5 ? `width:84px` : ``"
                      :key="index"
                    >
                      <span class="itemtext">{{ item.title }}</span>
                    </span>
                    <span
                      @mouseleave="blinkshandleLeave"
                      @click="
                        () => {
                          goDiv(item.class, item.url);
                        }
                      "
                      v-if="bselectindex == index"
                      class="itemf flex-col justify-between"
                      :style="index == 5 ? `width:84px` : ``"
                      :key="index"
                    >
                      <span class="itemtext">{{ item.title }}</span>

                      <div
                        class="lined"
                        :style="index == 5 ? `width:84px` : ``"
                      >
                        <img
                          class="line"
                          referrerpolicy="no-referrer"
                          src="../assets/imgs/line.png"
                        />
                      </div>
                    </span>
                  </template>

                  <span
                    :class="`${
                      lag == 'en' ? 'item' : 'itemf'
                    } flex-col justify-between`"
                    @click="
                      () => {
                        changelag('');
                      }
                    "
                  >
                    <span class="itemtext">中</span>
                  </span>
                  <span
                    class="item flex-col justify-between"
                    style="margin-left: 5px"
                  >
                    <span class="itemtext">/</span>
                  </span>
                  <span
                    :class="` ${
                      lag == 'en' ? 'itemf' : 'item'
                    }  flex-col justify-between`"
                    style="margin-left: 5px"
                    @click="
                      () => {
                        changelag('en');
                      }
                    "
                  >
                    <span class="itemtext">EN</span>
                  </span>
                </div>

                <div
                  class="showbar"
                  @click="openBardiv"
                  v-if="barclass == 'closethis'"
                ></div>
              </div>

              <div class="darkback" :style="{ opacity: topbarOpacity }"></div>
            </div>

            <div
              class="searcharea group_10 flex-row justify-between"
              style="margin-top: 16rem"
            >
              <div class="searchdiv">
                <input
                  type="text"
                  :placeholder="
                    lag == 'en'
                      ? 'Enter the desired information...'
                      : `输入想了解的…`
                  "
                  v-model="searchkey"
                  id="searchkeyinput"
                />
                <div class="searchbtn" @click="searchplist">
                  <div class="search"></div>
                </div>
              </div>
            </div>

            <div
              class="group_10 flex-col justify-between"
              style="margin-top: 8rem; width: 142rem"
            >
              <div class="mkdiv flex-row justify-between" style="width: 142rem">
                <a class="mkitema">
                  <div
                    :class="`mkitem ${
                      thisclass2 == '' ? 'selected' : ''
                    } flex-col`"
                    style="padding-left: 3rem; padding-right: 3rem"
                    @click="
                      () => {
                        selectclass2('');
                      }
                    "
                  >
                    <span class="mktext" v-if="lag == 'en'">ALL</span>
                    <span class="mktext" v-else>全部</span>
                  </div>
                </a>

                <template v-for="(item, index) in mkinds">
                  <a class="mkitema" :key="index">
                    <div
                      :class="`mkitem flex-col  ${
                        thisclass2 == item.id ? 'selected' : ''
                      }`"
                      @click="
                        () => {
                          selectclass2(item.id);
                        }
                      "
                    >
                      <span class="mktext" v-html="item.name"></span>
                    </div>
                  </a>
                </template>
              </div>

              <div class="mkdiv mkeydiv flex-row justify-between">
                <!-- <div class="keywordlable">关键词</div> -->

                <a class="mkitema">
                  <div
                    :class="`mkitem ${
                      thiskeysindex == '-1' ? 'selected' : ''
                    } flex-col`"
                    style="padding-left: 3rem; padding-right: 3rem"
                    @click="
                      () => {
                        selectkeyword('', '-1');
                      }
                    "
                  >
                    <span class="mktext" v-if="lag == 'en'">ALL</span>
                    <span class="mktext" v-else>全部</span>
                  </div>
                </a>

                <template v-for="(item, index) in mkeys">
                  <a class="mkitema" :key="index">
                    <div
                      :class="`mkitem flex-col  ${
                        thiskeysindex == index ? 'selected' : ''
                      }`"
                      @click="
                        () => {
                          selectkeyword(item, index);
                        }
                      "
                    >
                      <span class="mktext" v-html="item"></span>
                    </div>
                  </a>
                </template>
              </div>

              <!-- <img
                class="image_21"
                referrerpolicy="no-referrer"
                src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngeda3e001b1ab1f2ff8ccdbb40d2fa36bc422f9eaebe86adbeab1f91224c04650"
              /> -->

              <!-- <div class="images_more_list" v-if="w750 == 0">
                <div class="imlitem imli0"></div>
                <div class="imlitem imli1"></div>
                <div class="imlitem imli2"></div>
                <div class="imlitem imli3"></div>
              </div> -->
            </div>

            <div
              class="pplist flex-row justify-between"
              style="margin-bottom: 10rem"
            >
              <svg
                v-if="dataloading"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="8rem"
                height="10rem"
                style="
                  width: 8rem;
                  height: 10rem;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  margin-top: -5rem;
                  margin-left: -4rem;
                  enable-background: new 0 0 50 50;
                "
                viewBox="0 0 24 30"
                xml:space="preserve"
              >
                <rect x="0" y="13" width="4" height="5" fill="#CCCCCC">
                  <animate
                    attributeName="height"
                    attributeType="XML"
                    values="5;21;5"
                    begin="0s"
                    dur="0.6s"
                    repeatCount="indefinite"
                  ></animate>
                  <animate
                    attributeName="y"
                    attributeType="XML"
                    values="13; 5; 13"
                    begin="0s"
                    dur="0.6s"
                    repeatCount="indefinite"
                  ></animate>
                </rect>
                <rect x="10" y="13" width="4" height="5" fill="#CCCCCC">
                  <animate
                    attributeName="height"
                    attributeType="XML"
                    values="5;21;5"
                    begin="0.15s"
                    dur="0.6s"
                    repeatCount="indefinite"
                  ></animate>
                  <animate
                    attributeName="y"
                    attributeType="XML"
                    values="13; 5; 13"
                    begin="0.15s"
                    dur="0.6s"
                    repeatCount="indefinite"
                  ></animate>
                </rect>
                <rect x="20" y="13" width="4" height="5" fill="#CCCCCC">
                  <animate
                    attributeName="height"
                    attributeType="XML"
                    values="5;21;5"
                    begin="0.3s"
                    dur="0.6s"
                    repeatCount="indefinite"
                  ></animate>
                  <animate
                    attributeName="y"
                    attributeType="XML"
                    values="13; 5; 13"
                    begin="0.3s"
                    dur="0.6s"
                    repeatCount="indefinite"
                  ></animate>
                </rect>
              </svg>

              <template v-if="data.length > 0">
                <template v-for="(item, index) in data">
                  <div
                    :class="`ppitem ${ppindex == index ? 'ppselected' : ''}`"
                    :style="`animation-delay: ${index * 0.1}s;`"
                    :key="index"
                    @mouseover="
                      () => {
                        pphandleHover(index);
                      }
                    "
                    @touchstart="
                      () => {
                        pphandleHover(index);
                      }
                    "
                    @mouseleave="pphandleLeave"
                  >
                    <img
                      :class="`ppimg ${ppindex == index ? 'ppimgshow' : ''}`"
                      referrerpolicy="no-referrer"
                      :src="`${baseUrl + item.imgary[0].url}`"
                    />

                    <div
                      class="block_6 flex-col"
                      v-if="ppindex == index"
                      @click="
                        () => {
                          if (item.url == '') {
                            swSlideTo(item.swiperindex,item.id);
                          } else {
                            gowebsite(item.url);
                          }
                        }
                      "
                    >
                      <div class="ppinfo">
                        <span
                          class="text_57"
                          v-if="lag == 'en' && item.enpname != ''"
                          v-html="item.enpname"
                        ></span>
                        <span class="text_57" v-else v-html="item.pname"></span>

                        <div class="block_7 flex-row justify-start">
                          <!-- <template
                            v-for="(item2, index2) in item.keywords.split(',')"
                          >
                            <div
                              class="text-wrapper_23 flex-col"
                              v-if="item2 != ``"
                              :key="index2"
                            >
                              <span class="text_58" v-html="item2"></span>
                            </div>
                          </template> -->

                          <template v-if="lag == 'en' && item.enkeywords != ''">
                            <template
                              v-for="(item2, index2) in item.enkeywords.split(
                                ','
                              )"
                            >
                              <div
                                class="text-wrapper_23 flex-col"
                                v-if="item2 != ``"
                                :key="index2"
                              >
                                <span class="text_58" v-html="item2"></span>
                              </div>
                            </template>
                          </template>
                          <template v-else>
                            <template
                              v-for="(item2, index2) in item.keywords.split(
                                ','
                              )"
                            >
                              <div
                                class="text-wrapper_23 flex-col"
                                v-if="item2 != ``"
                                :key="index2"
                              >
                                <span class="text_58" v-html="item2"></span>
                              </div>
                            </template>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
              <template v-if="nopplistshow">
                <div class="nopplist">- NONE -</div>
              </template>
            </div>

            <footer-index :w750="w750" :basedata="basedata" :lag="lag" />
          </div>

          <div class="showppdiv">
            <div class="closesw"></div>
            <div class="swiper sc">
              <div class="swiper-wrapper">
                <template v-for="(item, index) in data">
                  <div
                    class="swiper-slide ss0"
                    v-if="item.url == ''"
                    :key="index"
                  >
                    <div class="ss ss0">
                      <div class="ssin">
                        <div class="scontent">
                          <div
                            class="sstitle"
                            v-if="lag == 'en' && item.enpname != ''"
                            v-html="item.enpname"
                          ></div>
                          <div v-else class="sstitle" v-html="item.pname"></div>

                          <!-- <div class="ppkinds flex-row justify-start">
                            <template
                              v-for="(item2, index2) in item.keywords.split(
                                ','
                              )"
                            >
                              <div class="ppkitem flex-col" :key="index2">
                                <span class="ppktext" v-html="item2"></span>
                              </div>
                            </template>
                          </div> -->

                          <div
                            class="ppkinds flex-row justify-start"
                            v-if="lag == 'en' && item.enkeywords != ''"
                          >
                            <template
                              v-for="(item2, index2) in item.enkeywords.split(
                                ','
                              )"
                            >
                              <div class="ppkitem flex-col" :key="index2">
                                <span class="ppktext" v-html="item2"></span>
                              </div>
                            </template>
                          </div>
                          <div class="ppkinds flex-row justify-start" v-else>
                            <template
                              v-for="(item2, index2) in item.keywords.split(
                                ','
                              )"
                            >
                              <div class="ppkitem flex-col" :key="index2">
                                <span class="ppktext" v-html="item2"></span>
                              </div>
                            </template>
                          </div>

                          <div
                            class="ppcontent"
                            v-if="lag == 'en' && item.encontent != ''"
                            v-html="item.encontent"
                          ></div>
                          <div
                            class="ppcontent"
                            v-else
                            v-html="item.content"
                          ></div>

                          <!-- <div class="ppcontent" v-html="item.content"></div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <div class="swiper-pagination"></div>
              <div class="swiper-button-next"></div>
              <div class="swiper-button-prev"></div>
            </div>
          </div>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import { fetchClass, fetchCase, fetchFriend, fetchBase } from "../api/index";
// const Base64 = require('js-base64').Base64
import FooterIndex from "../components/FooterIndex.vue";
import Swiper, {
  FreeMode,
  Autoplay,
  Effect,
  Pagination,
  Mousewheel,
  Scrollbar
} from "swiper";
Swiper.use([FreeMode, Autoplay, Effect, Pagination, Mousewheel,Scrollbar]);
import "swiper/swiper-bundle.css";
import global from "../common";
import $ from "jquery";
export default {
  name: "index",
  components: {
    FooterIndex,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      alive: true,
      from: "",
      lag: "",
      searchkey: "",
      pid: 0,
      w750: 0,
      m_group_2_height: 0,
      basedata: [],
      frienddata: [],
      ppswiperindex: 0,
      nopplistshow: 0,
      baseUrl: global.baseUrl,
      thisclass1: ["68", "69"],
      thisclass2: "",

      classlist: [],

      class2list: [],

      swiper: null,

      selectIndex: 0,

      barclass: "closethis",
      pageWidth: 1920,
      topbarOpacity: 0,
      ppindex: -1,
      pplist: [],
      bselectindex: -1,
      sectionindex: 0,
      mkinds: [],
      mkeys: [],
      data: [],
      dataloading: 0,
      pageSize: 1000,
      query: {
        class1: [],
        class2: [],
        searchkey: "",
      },

      blinks: [
        // { title: "公司简介", url: "index", class: "jianjie" },
        // { title: "客户伙伴", url: "index", class: "huoban" },
        // { title: "服务内容", url: "index", class: "fuwuneirong" },
        // { title: "项目案例", url: "index", class: "anli" },
        // { title: "联系我们", url: "index", class: "lianxiwomen" },
        // { title: "智能产品平台", url: "ai", class: "" },
      ],
      thiskeysindex: -1,
    };
  },
  activated() {
    let that = this;

    let from = this.$route.params.from;

    if (this.from != from) {
      if (from == "ai") {
        this.thisclass1 = ["69"];
        this.query.class1 = ["69"];
      } else {
        this.thisclass1 = ["68"];
        this.query.class1 = ["68"];
      }

      global.setPlistscroll(0);

      that.getClassList();

      that.data = [];
      that.mkeys = [];
      that.fetch();
      that.fetchb();
    }
    this.from = from;

    console.log("onActivated:", from);
    let scrollvalue = global.getPlistscroll();
    console.log("scrollvalue:", scrollvalue);
    if (scrollvalue > 0) {
      $(".main").scrollTop(scrollvalue);
    }
    this.alive = true;
  },
  deactivated() {
    console.log("deactivated:");
    this.blstarted = false;
    this.alive = false;
  },
  mounted() {
    let from = this.$route.params.from;
    let lag = this.$route.params.lag;
    this.from = from;
    this.lag = lag;
    console.log("this.lag:", this.lag);
    if (from == "ai") {
      this.thisclass1 = ["69"];
      this.query.class1 = ["69"];
    } else {
      this.thisclass1 = ["68"];
      this.query.class1 = ["68"];
    }
    let that = this;
    global.setPlistscroll(0);
    this.handleScroll;
    that.resizepage();
    window.addEventListener("scroll", this.handleScroll, true);
    window.addEventListener("resize", this.handleResize, true);
    this.myswiper();
    setTimeout(() => {
      $(document).on("click", ".closesw", function () {
        $(".showppdiv").fadeTo(300, 0, () => {
          $(".showppdiv").css({ left: "-100%" });
        });
      });
    }, 1000);
    that.getClassList();

    that.data = [];
    that.fetch();
    that.fetchb();

    document.getElementById("searchkeyinput").onkeyup = function (event) {
      var e = event || window.event;
      if (e && e.keyCode == 13) {
        that.searchplist();
      }
    };
  },
  beforeCreate() {
    let that = this;
  },
  methods: {
    searchplist() {
      this.query.searchkey = this.searchkey;
      this.fetch();

      this.nopplistshow = 0;
      this.dataloading = 1;
      this.data = [];
      setTimeout(() => {
        this.fetch();
      }, 500);
    },
    selectclass2(id) {
      this.nopplistshow = 0;
      this.dataloading = 1;
      this.thisclass2 = id;
      console.log(id);

      this.query.class2 = [id];

      this.data = [];
      setTimeout(() => {
        this.fetch();
      }, 500);
    },
    selectkeyword(keyword, index) {
      if (this.thiskeysindex == index) {
        this.thiskeysindex = -1;
        this.query.keywords = [];
      } else {
        this.thiskeysindex = index;
        this.query.keywords = [keyword];
      }

      if (keyword == "") {
        this.query.keywords = [];
      }

      this.nopplistshow = 0;
      this.dataloading = 1;

      this.data = [];

      console.log("this.query:", this.query);
      setTimeout(() => {
        this.fetch();
      }, 500);
    },
    getClassList() {
      const that = this;
      fetchClass().then((res) => {
        console.log(
          "%cfetchClass",
          "color: green;font-size: 14px;font-weight: bold;",
          res
        );
        that.classlist = res.results.list;

        console.log("classlist:", that.classlist);

        // console.log("fetchClass:",this.classlist.filter((it) => {
        //   console.log(this.thisclass1.indexOf(it.id)>-1);
        //   return this.thisclass1.indexOf(it.id)>-1;
        // }));

        let clists = this.classlist.filter((it) => {
          return this.thisclass1.indexOf(it.id) > -1;
        });

        that.class2list = [];
        clists.map((item, index) => {
          item.list.map((item2, index2) => {
            that.class2list.push(item2);
          });
        });

        console.log("class2list:", that.class2list);

        // console.log("class2list:", that.class2list);

        let mkinds = [];

        that.class2list.map((item) => {
          let cname = item.cname;
          if (that.lag == "en") {
            cname = item.encname;
          }
          mkinds.push({ name: cname, id: item.id });
        });

        that.mkinds = mkinds;

        console.log("mkinds:", this.mkinds);
      });
    },

    swSlideTo(index,id) {
      // alert(this.w750);
      if (this.w750 == 0) {
      } else if (this.w750 == 1) {
        if (this.lag == "en") {
          this.$router.push(`/pdetall/${id}/en`);
        } else {
          this.$router.push(`/pdetall/${id}`);
        }
        return false;
      }
      let that = this;
      this.swiper.slideTo(index, 0);
      $(".showppdiv").css({ left: "0%" });
      $(".showppdiv").fadeTo(300, 1, () => {});
    },
    gowebsite(url) {
      window.open(url);
    },
    myswiper() {
      let that = this;

      setTimeout(() => {
        this.swiper = new Swiper(".swiper", {
          pagination: {
            // el: '.swiper-pagination',
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          on: {
            transitionEnd: function () {
              that.selectIndex = this.activeIndex;
              console.log(that.selectIndex);
            },
            init: function (swiper) {
              this.emit("transitionEnd"); //在初始化时触发一次transitionEnd事件，需要先设置transitionEnd
            },
          },
        });
      }, 10);
    },

    fetch(
      params = {
        show: 1,
        en: this.lag,
        pagesize: this.pageSize,
        query: this.query,
      }
    ) {
      this.ppswiperindex = 0;
      let that = this;
      this.loading = true;

      console.log("this.query:", this.query);

      console.log("params:", params);

      fetchCase(params).then((res) => {
        console.log("fetchCase:", res);
        const pagination = { ...that.pagination };
        pagination.total = parseInt(res.results.total);
        pagination.pageSize = that.pageSize;
        that.loading = false;
        if (res.results.list) {
          that.data = res.results.list;
          that.nopplistshow = 0;
        } else {
          that.nopplistshow = 1;
        }
        that.dataloading = 0;
        that.pagination = pagination;
        // console.log("data:", that.data);
        setTimeout(() => {
          that.swiper.update();
        }, 100);

        let mkeys = [];
        // console.log("results:", res);
        res.results.keywords.map((item) => {
          mkeys.push(item);
        });
        that.mkeys = mkeys;
        // console.log("mkeys:", mkeys);
      });
    },
    fetchf(params = { pagesize: 1000 }) {
      let that = this;
      this.loading = true;

      fetchFriend(params).then((res) => {
        console.log("fetchFriend:", res);
        const pagination = { ...that.pagination };
        pagination.total = parseInt(res.results.total);
        pagination.pageSize = that.pageSize;
        that.loading = false;

        that.frienddata = res.results.list;

        // console.log("data:", that.frienddata);
      });
    },

    fetchb(params = {}) {
      let that = this;
      this.loading = true;

      fetchBase(params).then((res) => {
        console.log("fetchBase:", res.results);
        that.loading = false;
        that.basedata = res.results;

        if (that.from == "index") {
          that.blinks = [
            { title: "公司简介", url: "index", class: "jianjie" },
            { title: "客户伙伴", url: "index", class: "huoban" },
            { title: "服务内容", url: "index", class: "fuwuneirong" },
            { title: "项目案例", url: "index", class: "anli" },
            { title: "联系我们", url: "index", class: "lianxiwomen" },
            { title: "智能产品平台", url: "ai", class: "" },
          ];

          that.blinks[0].title = res.results.t0;
          that.blinks[1].title = res.results.t1;
          that.blinks[2].title = res.results.t2;
          that.blinks[3].title = res.results.t3;
          that.blinks[4].title = res.results.t4;
          that.blinks[5].title = res.results.t5;
          if (that.lag == "en") {
            that.blinks[0].title = res.results.ent0;
            that.blinks[1].title = res.results.ent1;
            that.blinks[2].title = res.results.ent2;
            that.blinks[3].title = res.results.ent3;
            that.blinks[4].title = res.results.ent4;
            that.blinks[5].title = res.results.ent5;
          }
        } else if (that.from == "ai") {
          that.blinks = [
            { title: "平台内容", url: "ai", class: "pingtaineirong" },
            { title: "发展历程", url: "ai", class: "fazhanlicheng" },
            { title: "项目案例", url: "ai", class: "anli" },
            { title: "联系我们", url: "ai", class: "lianxiwomen" },
            { title: "派志官网", url: "index", class: "" },
          ];

          that.blinks[0].title = res.results.t6;
          that.blinks[1].title = res.results.t7;
          that.blinks[2].title = res.results.t8;
          that.blinks[3].title = res.results.t9;
          that.blinks[4].title = res.results.t10;

          if (that.lag == "en") {
            that.blinks[0].title = res.results.ent6;
            that.blinks[1].title = res.results.ent7;
            that.blinks[2].title = res.results.ent8;
            that.blinks[3].title = res.results.ent9;
            that.blinks[4].title = res.results.ent10;
          }
        }

        if (that.lag == "en") {
          that.basedata.tel = res.results.entel;
          that.basedata.adr = res.results.enadr;
          that.basedata.email = res.results.enemail;
        }
      });
    },

    openBardiv() {
      this.barclass = "openthis";
      // alert(this.barclass);
    },
    closeBardiv() {
      this.barclass = "closethis";
    },
    handleResize() {
      this.resizepage();
    },
    resizepage() {
      let that = this;
      this.pageWidth = window.innerWidth;
      let pageHeight = window.innerHeight;
      // if (window.innerWidth > 1920) {
      //   this.pageWidth = 1920;
      // }
      if (window.innerWidth <= 750) {
        this.sectionindex = 0;
        this.m_group_2_height = pageHeight;
        this.w750 = 1;
      } else {
        this.m_group_2_height = 0;
        this.w750 = 0;
      }
      console.log("resize", this.pageWidth);
      // setTimeout(() => {
      $("html").css({ fontSize: this.pageWidth / 192 });
      // }, 500);

      // setTimeout(() => {
      //   that.resizepage();
      // },100);
    },
    handleScroll(e) {
      if (this.alive) {
        const winHeight =
          e.target.scrollTop || document.documentElement.scrollTop;
        global.setPlistscroll(winHeight);
        console.log("plist scroll:", winHeight);
        if (winHeight > 400) {
          this.topbarOpacity = (winHeight - 400) / 200;
          if (this.topbarOpacity > 1) {
            this.topbarOpacity = 1;
          }
        } else {
          this.topbarOpacity = 0;
        }
      }
    },

    changelag(lag) {
      if (lag == "en") {
        this.$router.push(`/plist/${this.from}/en`);
        setTimeout(() => {
          location.reload();
        }, 100);
      } else {
        this.$router.push(`/plist/${this.from}`);
        setTimeout(() => {
          location.reload();
        }, 100);
      }
    },

    goDiv(classtext, url) {
      this.barclass = "closethis";
      global.setIndexDclass(classtext);
      // alert(global.getIndexDclass());
      if (url != "") {
        global.setIndexscroll(0);
        if (this.lag == "en") {
          url = `/${url}/en`;
        }
        this.$router.push(url);
      }
    },
    blinkshandleHover(index) {
      this.bselectindex = index;
      console.log(this.bselectindex);
    },
    sectionhandleHover(index) {
      if (this.w750 == 0) this.sectionindex = index;
    },
    blinkshandleLeave() {
      this.bselectindex = -1;
    },
    pphandleHover(index) {
      this.ppindex = index;
    },
    pphandleLeave() {},
  },
};
</script>


<style src="../assets/css/common.css" />
<style src="../assets/css/index.css" />
<style src="../assets/css/index1280.css" />
<style src="../assets/css/index750.css" />

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
